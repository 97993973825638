<!-- 年级管理 -->
<template>
  <div class="container">
    <div class="main">
      <div class="header_box flexSb">
        <div class="flexSt">
          <div class="flexSt list">
            <div class="title">年级名称</div>
            <el-select
              filterable
              @change="handleSearch"
              v-model="form.name"
              placeholder="请输入年级名称">
              <el-option
                v-for="(item, index) in teacherlist"
                :key="index"
                :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </div>

          <div class="flexSt list">
            <div class="title">入学年份</div>
            <el-date-picker
              v-model="form.year"
              type="year"
              placeholder="请选择日期">
            </el-date-picker>
          </div>
        </div>
        <div class="flexSb list">
          <el-button @click="handleSearch" type="primary" icon="el-icon-search"
            >搜索</el-button
          >
          <el-button @click="handleclearSearch" icon="el-icon-refresh"
            >重置</el-button
          >
        </div>
      </div>
      <!-- <div class="add">
      <div class="flexSt">
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="handleEdit('/editTeacher')"
          >新增年级</el-button
        ><el-button icon="el-icon-delete" @click="handleShowDel">删除</el-button
        ><el-button icon="el-icon-top" @click="handleShowDel">升级</el-button>
      </div>
    </div> -->

      <div class="table_box">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          :header-cell-style="tableHeaderColor"
          :header-cell-class-name="cellClass"
          :cell-class-name="returnName">
          <el-table-column type="selection" width="25"> </el-table-column>
          <el-table-column
            :index="indexMethod"
            type="index"
            label="序号"
            width="60"
            align="center">
          </el-table-column>

          <el-table-column width="120" label="年级名称" sortable align="center">
            <template slot-scope="scope">
              <div style="padding-right: 16px">一年级</div>
            </template>
          </el-table-column>

          <el-table-column width="120" label="入学年份" sortable align="center">
            <template slot-scope="scope">
              <div style="padding-right: 16px">2022</div>
            </template>
          </el-table-column>
          <el-table-column width="120" label="排序值" sortable align="center">
            <template slot-scope="scope">
              <div style="padding-right: 16px">0</div>
            </template>
          </el-table-column>
          <el-table-column min-width="120" label="备注" align="center">
            <template slot-scope="scope">
              <div>备注备注备注备注备注</div>
            </template>
          </el-table-column>

          <el-table-column
            width="100"
            fixed="right"
            label="操作"
            align="center">
            <template slot-scope="scope">
              <div class="flexSc">
                <el-button
                  type="text"
                  @click="handleEdit('/editTeacher?id=' + scope.row.id)"
                  >编辑</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>

        <div class="flexSe elpla">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageInfo.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageInfo.total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      schoollist: [],
      form: {
        school: "",
        name: "",
        year: "",
      },
      tableData: [12],
      pageInfo: {
        pageSize: 10,
        page: 1,
        total: 1,
      },
    };
  },
  methods: {
    handleSizeChange() {},
    handleCurrentChange() {},
    handleEdit() {},
    returnName(obj) {
      if (obj.columnIndex == 0) {
        return "firstCell";
      }
    },
    cellClass() {
      return "disableSelection";
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      return {
        fontSize: "16px",
        color: "#333333",
        paddingTop: "8px",
        paddingBottom: "8px",
        backgroundColor: "#F7F7F7",
      };
    },
    handleSelectionChange() {},
  },
};
</script>
<style lang="less" scoped>
.container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: stretch;
  padding: 20px;
  box-sizing: border-box;
  min-height: 100%;
}
.main {
  flex: 1;
  background-color: #fff;
  border-radius: 4px;
  padding: 35px 25px;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
}
.container {
  /deep/.firstCell {
    .cell {
      overflow: unset !important;
    }
  }
  .elpla {
    margin-top: 30px;
  }
  // ::-webkit-scrollbar {
  //   width: 10px !important;
  //   height: 10px !important;
  //   background-color: #ccc;
  // }
  ::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
    // background-color: #ccc !important;
  }
  ::-webkit-scrollbar-track {
    // background-color: #bbbbbb;
  }
  /* 滚动条上的滚动滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
  .add {
    margin: 17px 0;
    /deep/.el-button {
      padding: 0 20px;
      height: 30px !important;
      line-height: 30px;
    }
  }
  .header_box {
    border-bottom: 1px solid rgba(242, 242, 242, 1);
    padding-bottom: 20px;
    box-sizing: border-box;
    flex-wrap: wrap;
    .list {
      margin-bottom: 10px;
    }
    .list2 {
      /deep/.el-cascader {
        width: 147px !important;
        margin-right: 10px;
      }
    }
    /deep/.title {
      color: rgb(78, 89, 105);
      font-size: 14px;
      margin-right: 8px;
      // min-width: 60px;
    }
    .sex {
      /deep/.el-form-item__label {
        width: 47px !important;
      }
      /deep/.el-form-item__content {
        margin-left: 60px !important;
      }
      /deep/.el-select {
        width: 127px !important;
        height: 32px !important;
      }
      /deep/.el-form-item__content {
        width: 127px !important;
      }
    }
    .sex2 {
      /deep/.el-form-item__label {
        width: 80px !important;
      }
      /deep/.el-form-item__content {
        width: 167px !important;
      }
      /deep/.el-select {
        width: 147px !important;
        height: 32px !important;
      }
    }
    .sex3 {
      /deep/.el-select,
      /deep/.el-input__inner,
      /deep/.el-input {
        width: 127px !important;
        height: 32px !important;
      }
      /deep/.el-form-item__content {
        // margin-left: 40px !important;
      }
    }
    /deep/.el-button {
      padding: 0 20px;
      height: 30px !important;
      line-height: 30px;
    }
    /deep/.el-input,
    /deep/.el-form-item__content,
    /deep/.el-input__inner,
    /deep/.el-form-item__labe {
      height: 32px !important;
      width: 147px;
    }
    /deep/.el-input {
      margin-right: 15px;
    }

    /deep/.el-input__icon {
      line-height: 32px !important;
    }

    /deep/.el-form-item__content {
      width: 171px !important;
    }
  }
}
</style>
